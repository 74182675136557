<template>
  <div class="footer" >
    <div class="is-hidden-mobile">
      <section class="logo"><img :src="logo" alt="" ></section>
      <div class="columns px-6">
        <div class="column" v-for="item in menuList">
          <p class="is-size-5"><a :href="item.url? item.url:'javascript:void(0)'" >{{item.name}}</a></p>
          <ul>
            <li v-for="sub in item.children">
              <p>
                <a :href="sub.url" >{{sub.name}}</a>
              </p>
            </li>
          </ul>
        </div>
        <div class="column" >
          <p class="is-size-5"> {{contectData.name}}</p>
          <p class="is-size-6"> {{contectData.time}}</p>
         
          <ul>
            <li >
               <div class="columns  contact_hover is-relative">
                <div class="column">
                  <img :src="wx" alt=""  class="img mt-1">
                  <img :src="contectData.wechat" alt=""  class="hide">
                </div>
                <div class="column">
                  <img :src="zx" alt="" class="img mt-1">
                  <p class="hide">{{contectData.email}}</p>
                </div>
                <div class="column">
                  <img :src="dt" alt="" class="img mt-1">
                  <p class="hide">{{contectData.address}}</p>
                </div>
               </div>
            </li>
          </ul>
        </div>
      </div>
      <hr class="divider">
    </div> 
    
    <section class="content">
       <div class="columns">
        <div class="column has-text-centered-mobile"><a :href="banquanData.trademark.url" target="_self">{{banquanData.trademark.text}}</a> | <a :href="banquanData.legalNotice.url" target="_self">{{banquanData.legalNotice.text}}</a></div>
        <div class="column line-height-1 has-text-right-desktop has-text-centered-mobile">
          <p>{{banquanData.Copyright.text}} </p>
           <p><a :href="banquanData.filings.url" target="_self">{{banquanData.filings.text}}</a></p>
        </div>
       </div>
    </section>
  </div>
</template>
<script setup>
import { ref ,reactive,onMounted ,computed } from 'vue'
import wx from '@_/assets/wx.jpg'
import zx from '@_/assets/zx.jpg'
import dt from '@_/assets/dt.jpg'
import "@_/assets/css/footer.less"
import {EventBus,EventConstance} from '@_/util/bus.js'
import Lang_ from "../assets/lang/lang.js"
import { httpGet } from '../util/http'
import dataAPI from '../util/dataApi'
import logo from '@_/assets/logo_.png'

const Lang = reactive(Lang_)
const lang = ref('cn')



const menuList = ref([
  // {key:'home',name:'关于Sigmastar',url:'' ,children:[
  //    {key:'home',name:'关于Sigmastar',url:''},
  //    {key:'home',name:'公司地址',url:''},
  //    {key:'home',name:'联系我们',url:''},
  // ]},
  // {key:'home',name:'市场几解决方案',url:'' ,children:[
  //    {key:'home',name:'关于Sigmastar',url:''},
  //    {key:'home',name:'公司地址',url:''},
  //    {key:'home',name:'联系我们',url:''},
  // ]},
  // {key:'home',name:'新闻中心',url:'' ,children:[
  //    {key:'home',name:'关于Sigmastar',url:''},
  //    {key:'home',name:'公司地址',url:''},
  //    {key:'home',name:'联系我们',url:''},
  // ]},
  // {key:'home',name:'人力资源',url:'' ,children:[
  //    {key:'home',name:'关于Sigmastar',url:''},
  //    {key:'home',name:'公司地址',url:''},
  //    {key:'home',name:'联系我们',url:''},
  // ]},
])

const contectData = ref({
  name:'媒体互动',
  time:'工作时间 (周一到周五 9：00-18：00)',
  wechat:'https://www.sigmastar.com.cn/uploadfile/image/20200810/20200810170352_33354.jpg',
  email:'lisa.ao@sigmastar.com.tw',
  address:'深圳市那么山区 崇文园区29层'
})

const banquanData = computed(()=>{
  return{
  trademark:{text:Lang[lang.value]['copyright'],url:'/copyright'},
  legalNotice:{text:Lang[lang.value]['legalNotice'],url:'/legalNotice'},
  filings:{text:Lang[lang.value]['filling_number'],url:'https://beian.miit.gov.cn/#/Integrated/index'},
  Copyright:{text:Lang[lang.value]['copyright_belongs']}
}
})


onMounted(()=>{
  EventBus.$on(EventConstance.LANG,(data)=>{
    lang.value = data
    get_quicklink()
     get_contact()
 
      })

      get_quicklink()
       get_contact()

})

const get_quicklink = ()=>{
  httpGet(dataAPI['get_quicklink'])
  .then(data=>{
    menuList.value = data.quicklinks
  })
}

const get_contact = ()=>{
  httpGet(dataAPI['get_contactData'])
  .then(data=>{
    contectData.value.wechat = data.wechat.img_src
    contectData.value.email = data.email.value
    contectData.value.address = data.address.value
    contectData.value.time = data.time.value
    contectData.value.name = data.name.value
  })
}

</script>
<style scoped>
.footer a{
  color:#9f9f9f;
}
</style>
